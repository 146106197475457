import Azure from '@/assets/azure.svg?react'
import Airia from '@/assets/models/Airia.svg?react'
import Anthropic from '@/assets/models/Anthropic.svg?react'
import ChatGPT from '@/assets/models/ChatGPT.svg?react'
import Gemini from '@/assets/models/Gemini.svg?react'
import Mistral from '@/assets/models/Mistral.svg?react'
import Ollama from '@/assets/models/Ollama.svg?react'
import HuggingFace from '@/assets/models/huggingFace.svg?react'

const ProviderIcon: React.FC<{ provider: string; className?: string }> = ({
    provider,
    className,
}) => {
    switch (provider) {
        case 'Airia':
            return <Airia className={className} />
        case 'Anthropic':
            return <Anthropic className={className} />
        case 'Azure':
            return <Azure className={className} />
        case 'Gemini':
            return <Gemini className={className} />
        case 'Hugging Face':
            return <HuggingFace className={className} />
        case 'Mistral':
            return <Mistral className={className} />
        case 'Ollama':
            return <Ollama className={className} />
        case 'OpenAI':
            return <ChatGPT className={className} />
        default:
            return <Airia className={className} />
    }
}

export default ProviderIcon
