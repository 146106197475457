import Activity from '@/assets/icons/activity.svg?react'
import BarChartCircle from '@/assets/icons/bar-chart-circle.svg?react'
import ProxyFeed from '@/components/home/feed/ProxyFeed'
import Overview from '@/components/home/overview/Overview'
import * as Tabs from '@radix-ui/react-tabs'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/_mainLayout/')({
    component: Dashboard,
})

function Dashboard() {
    return (
        <div className="flex flex-col gap-4 px-10">
            <Tabs.Root className="flex flex-col" defaultValue="overview">
                <Tabs.List className="shrink-0 flex border-b border-mauve6 w-full gap-10">
                    <Tabs.Trigger
                        className="bg-background px-1 h-[45px] w-fit flex items-center justify-center text-[15px] leading-none select-none first:rounded-tl-md last:rounded-tr-md hover:text-violet11 data-[state=active]:text-violet11 data-[state=active]:shadow-[inset_0_-1px_0_0,0_1px_0_0] data-[state=active]:focus:relative data-[state=active]:focus:shadow-primary data-[state=active]:shadow-primary outline-none cursor-default data-[state=active]:text-primary"
                        value="overview"
                    >
                        <BarChartCircle className="w-6 h-6 mr-2 -ml-1" />
                        Overview
                    </Tabs.Trigger>
                    <Tabs.Trigger
                        className="bg-background px-1 h-[45px] w-fit flex items-center justify-center text-[15px] leading-none select-none first:rounded-tl-md last:rounded-tr-md hover:text-violet11 data-[state=active]:text-violet11 data-[state=active]:shadow-[inset_0_-1px_0_0,0_1px_0_0] data-[state=active]:focus:relative data-[state=active]:focus:shadow-primary data-[state=active]:shadow-primary outline-none cursor-default data-[state=active]:text-primary"
                        value="feed"
                    >
                        <Activity className="w-6 h-6 mr-2 -ml-1" />
                        Feed
                    </Tabs.Trigger>
                </Tabs.List>
                <Tabs.Content value="overview">
                    <Overview />
                </Tabs.Content>
                <Tabs.Content value="feed">
                    <ProxyFeed />
                </Tabs.Content>
            </Tabs.Root>
        </div>
    )
}

export default Dashboard
