import apiRequest from '@/lib/apiClient'

export type Conversation = {
    conversationId: string
    userId: string
    title: string
    createdAt: string
    updatedAt: string
    messages: {
        id: string
        conversationId: string
        message: string
        createdAt: string
        updatedAt: string
        role: string
        images: string[]
    }[]
    latestMessage: any | null
    websocketUrl: string
}

export const getAllConversations = async (userId: string): Promise<Conversation[]> => {
    try {
        return await apiRequest(`/v1/users/${userId}/conversations`)
    } catch {
        return []
    }
}

export const getConversation = async (conversationId: string): Promise<Conversation> => {
    return apiRequest(`/v1/conversations/${conversationId}`)
}

export const createNewConversation = async (userId: string): Promise<Conversation> => {
    return apiRequest('/v1/Conversations', {
        method: 'POST',
        body: { title: 'New Conversation', userId },
    })
}

export const deleteConversation = async (conversationId: string): Promise<void> => {
    return apiRequest(`/v1/conversations/${conversationId}`, { method: 'DELETE' })
}
