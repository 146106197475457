import { WorkspaceSelector } from '@/components/workspaceSelector/WorkspaceSelector.tsx'
import { ProfileDropdown } from './ProfileDropdown'
import { HelpCenter } from './helpCenter'

export const TopNav = () => {
    return (
        <div className="h-16 flex items-center px-5 border-b shadow-sm">
            <div className={'flex w-full justify-end items-center gap-6'}>
                <HelpCenter />
                <WorkspaceSelector />
                <ProfileDropdown />
            </div>
        </div>
    )
}
