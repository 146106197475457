import Search from '@/assets/icons/search.svg?react'
import { useNavigate } from '@tanstack/react-router'
import { ColumnDef } from '@tanstack/react-table'
import { t } from 'i18next'
import { Input } from '../ui/input'
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from '../ui/select'

export type paginationAndSortingParams = {
    projectId?: string
    pageNumber: number
    pageSize: number
    sortBy?: string
    sortDirection?: 'ASC' | 'DESC'
}

export interface PaginationProps {
    queryParams: paginationAndSortingParams
    totalCount?: number
}

export interface PaginationHeaderProps<T> extends PaginationProps {
    filter: string
    setFilter: (filter: string) => void
    sortOptions?: ColumnDef<T>[]
}

const PaginationHeader = <T,>({
    queryParams,
    filter,
    setFilter,
    sortOptions,
}: PaginationHeaderProps<T>) => {
    const navigate = useNavigate()
    return (
        <div className="flex flex-row gap-2">
            {sortOptions && (
                <Select
                    value={queryParams.sortBy}
                    onValueChange={(val) => navigate({ search: { ...queryParams, sortBy: val } })}
                >
                    <SelectTrigger className="min-w-44 max-w-44 rounded-lg">
                        <SelectValue placeholder={t('sort_by')} />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectGroup>
                            <SelectLabel>{t('sort_by')}</SelectLabel>
                            {sortOptions.map((option) => {
                                // @ts-ignore
                                if (option.accessorKey && option.header) {
                                    return (
                                        // @ts-ignore
                                        <SelectItem value={option.accessorKey}>
                                            {option.header.toString()}
                                        </SelectItem>
                                    )
                                }
                            })}
                        </SelectGroup>
                    </SelectContent>
                </Select>
            )}
            <Input
                startIcon={Search}
                className="min-w-44 max-w-44 rounded-lg"
                placeholder={t('filter')}
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
            />
        </div>
    )
}

export default PaginationHeader
