import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs))
}

export function capitalizeFirstLetter(value: string) {
    if (!value) return value
    return value.charAt(0).toUpperCase() + value.slice(1)
}

export function isJSON(value: string): boolean {
    try {
        return !!value && !!JSON.parse(value)
    } catch (_) {
        return false
    }
}

export function isMarkdown(value: string): boolean {
    // const markdownRegex = [
    //     // ### Headers
    //     /(#{1,6}\s)(.*)/,
    //     // *_Italic_*
    //     /(\*|_)+(\S+)(\*|_)+/,
    //     // Bold text with double asterisks
    //     /\*\*[^*\n]+\*\*/,
    //     // [links](link.com)
    //     /(\[.*\])(\((http)(?:s)?(:\/\/).*\))/,
    //     // ![Images](link/to/image.png "text")
    //     /(!)(\[(?:.*)?\])(\(.*(\.(jpg|png|gif|tiff|bmp))(?:(\s"|').+("|'))?\))/,
    //     // * Unordered List
    //     /(^(\W{1})(\s)(.*)(?:$)?)+/,
    //     // ```json { "code": true }```
    //     /```[a-z]*\n[\s\S]*?\n```/,
    //     // > Block Quote
    //     /(\\`{1})(.*)(\\`{1})/,
    //     /(\\`{3}\\n+)(.*)(0\\n+\\`{3})/,
    //     /(=|-|\*){3}/,
    //     // --- horizontal line
    //     // Numbered list
    //     /^\d+\.\s.+/m,
    //     // Line breaks
    //     /\n{2,}/,
    // ]
    const markdownRegex =
        /(?:#{1,6}\s.*|[*_]+\S+[*_]+|\*\*[^*\n]+\*\*|\[.*\]\((?:https?:\/\/)?.*\)|\!\[(?:.*?)?\]\(.*\.(jpg|png|gif|tiff|bmp)(?:\s".*")?\)|^(\W{1})\s.*|```[a-z]*\n[\s\S]*?\n```|(?:`{1,3}).*(?:`{1,3})|(?:=|-|\*){3}|^\d+\.\s.+|\n{2,})/m

    return markdownRegex.test(value)
}

export function isBase64Image(value: string): boolean {
    const jpgPrefix = /\/9j\/[^\s"]+/
    const pngPrefix = /iVBORw0K[^\s"]+/
    let matchedValue: string | undefined
    if (jpgPrefix.test(value)) {
        matchedValue = value.match(jpgPrefix)?.at(0)
    } else if (pngPrefix.test(value)) {
        matchedValue = value.match(pngPrefix)?.at(0)
    }

    if (!matchedValue) return false

    try {
        return btoa(atob(matchedValue)) === matchedValue
    } catch (_) {
        return false
    }
}

export function getBase64String(value: string): string {
    const jpgPrefix = /\/9j\/[^\s"]+/
    const pngPrefix = /iVBORw0K[^\s"]+/
    let matchedValue = ''
    if (jpgPrefix.test(value)) {
        matchedValue = `data:image/jpg;base64,${value.match(jpgPrefix)?.at(0)}`
    } else if (pngPrefix.test(value)) {
        matchedValue = `data:image/png;base64,${value.match(pngPrefix)?.at(0)}`
    }

    return matchedValue
}

export function downloadFile(data: string, filename: string, type: string) {
    // Create a blob with the data we want to download as a file
    const blob = new Blob([data], { type })
    // Create an anchor element and dispatch a click event on it
    // to trigger a download
    const a = document.createElement('a')
    a.download = filename
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
}

export const getApiUrl = () => {
    const currentDomain = window.location.hostname
    let apiDomain

    if (currentDomain.includes('localhost') || currentDomain.includes('127.0.0.1')) {
        // For local development
        return import.meta.env.VITE_BASE_PATH
    } else {
        // For all other environments (dev, staging, production, etc.)
        apiDomain = currentDomain.replace(/^([^.]+)\./, '$1.api.')
    }

    const apiBaseUrl = `https://${apiDomain}`
    return `${apiBaseUrl}${import.meta.env.VITE_BASE_PATH}`
}
export const getAuthUrl = () => {
    const currentDomain = window.location.hostname
    let apiDomain

    if (currentDomain.includes('localhost') || currentDomain.includes('127.0.0.1')) {
        // For local development
        return ''
    } else {
        // For all other environments (dev, staging, production, etc.)
        apiDomain = currentDomain.replace(/^([^.]+)\./, '$1.api.')
    }

    const apiBaseUrl = `https://${apiDomain}`
    return `${apiBaseUrl}`
}
