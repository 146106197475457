import { Project, getAllProjects } from '@/api/projects'
import AiriaLogoBlack from '@/assets/airia-logo-black.svg?react'
import AiriaLogo from '@/assets/airia-logo.svg?react'
import AiriaIcon from '@/assets/icons/airia-icon.svg?react'
import Coins from '@/assets/icons/coins.svg?react'
import Database from '@/assets/icons/database.svg?react'
import Home from '@/assets/icons/home.svg?react'
import Tools from '@/assets/icons/tools.svg?react'
import User from '@/assets/icons/user.svg?react'
import Users from '@/assets/icons/users.svg?react'
import { Input } from '@/components/ui/input.tsx'
import { QueryKeys } from '@/constants/QueryKeys.ts'
import useTenantQuery from '@/hooks/use-tenant-query'
import { HandleAuthenticatedError } from '@/lib/HandleErrors'
import { useTheme } from '@/lib/ThemeProvider'
import { cn } from '@/lib/utils'
import { routeTree } from '@/routeTree.gen'
import { useTour } from '@reactour/tour'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { Link, RoutePaths, useNavigate } from '@tanstack/react-router'
import { Key, KeySquare, LibraryBig, Loader2, Pause, PlusIcon, Settings } from 'lucide-react'
import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CreateNewProject } from './administration/Projects'
import { Badge } from './ui/badge'
import { Separator } from './ui/separator'

const SideNavItem: React.FC<{
    icon: ReactElement
    title: string
    path?: RoutePaths<typeof routeTree>
    projectId?: string
    onClick?: () => void
    endAdornment?: ReactElement
    className?: string
}> = ({ icon, title, path, projectId, onClick, endAdornment, className }) => {
    if (path) {
        return (
            <Link
                to={path}
                params={{ projectId }}
                className={cn(
                    'flex rounded-lg items-center text-foreground hover:bg-background transition-colors p-2',
                    className
                )}
                activeProps={{
                    className: path ? 'text-primary hover:bg-accent' : '',
                }}
                activeOptions={{
                    exact: false,
                }}
                onClick={() => onClick && onClick()}
            >
                <div className="w-full flex items-center gap-4">
                    <div className="flex-shrink-0 w-6 flex items-center justify-center">{icon}</div>
                    <div className="flex-grow min-w-0 flex items-center justify-between">
                        <h3 className="truncate" title={title}>
                            {title}
                        </h3>
                        {endAdornment && <div className="flex-shrink-0 ml-2">{endAdornment}</div>}
                    </div>
                </div>
            </Link>
        )
    }
    return (
        <div
            className="w-full flex gap-4 rounded-lg items-center text-foreground hover:bg-background transition-colors p-2"
            onClick={() => onClick && onClick()}
        >
            <div className="flex-shrink-0 w-6 flex items-center justify-center">{icon}</div>
            <div className="flex-grow min-w-0 flex items-center justify-between">
                <h3 className="truncate cursor-pointer" title={title}>
                    {title}
                </h3>
                {endAdornment && <div className="flex-shrink-0 ml-2">{endAdornment}</div>}
            </div>
        </div>
    )
}

const RECENT_PROJECT_IDS_KEY = 'recentProjectIds'
const LAST_ACCESSED_PROJECT_KEY = 'lastAccessedProject'

interface ProjectWithRecent extends Project {
    isRecent: boolean
}

export const SideNav = () => {
    const { t } = useTranslation()
    const { theme } = useTheme()
    const AllProjectsQuery = useQuery({
        queryKey: [QueryKeys.PROJECTS],
        queryFn: async () => await getAllProjects().catch((e) => HandleAuthenticatedError(e)),
    })
    const [recentProjectIds, setRecentProjectIds] = useState<string[]>([])
    const [searchTerm, setSearchTerm] = useState('')

    const [openProjectSelector, setOpenProjectSelector] = useState(false)
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const { details, isLoading } = useTenantQuery()

    const { setCurrentStep } = useTour()

    useEffect(() => {
        const storedRecentProjectIds = localStorage.getItem(RECENT_PROJECT_IDS_KEY)
        if (AllProjectsQuery.isSuccess && AllProjectsQuery.data) {
            if (storedRecentProjectIds) {
                setRecentProjectIds(JSON.parse(storedRecentProjectIds))
            } else {
                const initialRecentProjectIds = AllProjectsQuery.data.slice(0, 5).map((p) => p.id)
                setRecentProjectIds(initialRecentProjectIds)
                localStorage.setItem(
                    RECENT_PROJECT_IDS_KEY,
                    JSON.stringify(initialRecentProjectIds)
                )
            }
            if (localStorage.getItem(LAST_ACCESSED_PROJECT_KEY) === null) {
                localStorage.setItem(LAST_ACCESSED_PROJECT_KEY, AllProjectsQuery.data[0].id)
            }
        }
    }, [AllProjectsQuery.isSuccess, AllProjectsQuery.data, recentProjectIds.length])

    const updateRecentProjects = (selectedProjectId: string) => {
        setRecentProjectIds((prev) => {
            const updatedRecent = [
                selectedProjectId,
                ...prev.filter((id) => id !== selectedProjectId),
            ].slice(0, 5)

            localStorage.setItem(RECENT_PROJECT_IDS_KEY, JSON.stringify(updatedRecent))
            return updatedRecent
        })

        localStorage.setItem(LAST_ACCESSED_PROJECT_KEY, selectedProjectId)
    }

    const sortedProjects = useMemo(() => {
        if (!AllProjectsQuery.data) return []

        const recentProjectSet = new Set(recentProjectIds)

        const projectsWithRecent: ProjectWithRecent[] = AllProjectsQuery.data.map((project) => ({
            ...project,
            isRecent: recentProjectSet.has(project.id),
        }))

        return projectsWithRecent.sort((a, b) => {
            if (a.isRecent !== b.isRecent) {
                return a.isRecent ? -1 : 1
            }
            return recentProjectIds.indexOf(a.id) - recentProjectIds.indexOf(b.id)
        })
    }, [AllProjectsQuery.data, recentProjectIds])

    const visibleProjects = sortedProjects.slice(0, 5)

    const filteredAndSortedProjects = useMemo(() => {
        return sortedProjects.filter((project) =>
            project.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
    }, [sortedProjects, searchTerm])

    const onProjectSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value)
    }

    const selectorRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (selectorRef.current && !selectorRef.current.contains(event.target as Node)) {
                setOpenProjectSelector(false)
            }
        }

        const handleEscape = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                setOpenProjectSelector(false)
            }
        }

        if (openProjectSelector) {
            document.addEventListener('mousedown', handleClickOutside)
            document.addEventListener('keydown', handleEscape)
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
            document.removeEventListener('keydown', handleEscape)
        }
    }, [openProjectSelector])

    return (
        <>
            <aside className="h-full overflow-y-auto z-10 min-w-60 max-w-60 border-r border-muted bg-card">
                <nav className="flex flex-col items-stretch gap-9 p-4 text-[13px] tracking-[0.26px]">
                    <Link
                        to="/"
                        className="flex rounded-lg items-center text-foreground transition-colors p-2"
                    >
                        {theme === 'dark' ? (
                            <AiriaLogo className="h-9 w-24 self-start" />
                        ) : (
                            <AiriaLogoBlack className="h-9 w-24 self-start" />
                        )}
                    </Link>
                    <div className="flex flex-col gap-2">
                        <SideNavItem
                            path="/"
                            title={t('home')}
                            icon={<Home className="w-4 h-4" stroke="currentcolor" />}
                        />
                        <div className="flex flex-row justify-between items-center">
                            <p className="text-sidenav-header font-gilroy-bold text-[13px] tracking-[0.26px] uppercase font-normal">
                                {t('side_nav_headers.project')}
                            </p>
                            <CreateNewProject
                                variant={'ghost'}
                                size="icon"
                                Icon={PlusIcon}
                                onSuccess={() => {
                                    queryClient.invalidateQueries({
                                        queryKey: [QueryKeys.PROJECTS],
                                    })
                                    navigate({ to: '/' })
                                }}
                            />
                        </div>
                        {visibleProjects.map((project, index) => (
                            <SideNavItem
                                key={project.id}
                                path="/$projectId/"
                                projectId={project.id}
                                title={project.name}
                                className={cn(index === 0 && 'first-step')}
                                onClick={() => {
                                    if (index === 0) {
                                        setTimeout(() => setCurrentStep((prev) => prev + 1), 250)
                                    }
                                    updateRecentProjects(project.id)
                                    localStorage.setItem(LAST_ACCESSED_PROJECT_KEY, project.id)
                                }}
                                icon={
                                    project.projectIcon ? (
                                        <img
                                            src={project.projectIcon}
                                            alt={project.name}
                                            height={17}
                                            width={17}
                                        />
                                    ) : (
                                        <AiriaIcon className="w-[17px] h-[17px]" />
                                    )
                                }
                            />
                        ))}
                        <SideNavItem
                            title={'View All'}
                            onClick={() => setOpenProjectSelector((prev) => !prev)}
                            icon={<Pause className="w-4 h-4" stroke="currentcolor" />}
                        />
                        <Separator />
                        <p className="text-sidenav-header font-gilroy-bold text-[13px] tracking-[0.26px] uppercase font-normal">
                            {t('side_nav_headers.libraries')}
                        </p>
                        <SideNavItem
                            path="/library"
                            title={t('models')}
                            icon={<LibraryBig className="w-4 h-4" stroke="currentcolor" />}
                        />
                        <SideNavItem
                            path="/tools/library"
                            title={t('tools.title')}
                            icon={<Tools className="w-4 h-4" stroke="currentcolor" />}
                        />
                        <SideNavItem
                            path="/datasources"
                            title={t('datasets')}
                            icon={<Database className="w-4 h-4" stroke="currentcolor" />}
                        />
                        <Separator className="mb-4" />
                        <p className="text-sidenav-header font-gilroy-bold text-[13px] tracking-[0.26px] uppercase font-normal">
                            {t('side_nav_headers.account')}
                        </p>
                        <SideNavItem
                            path="/credits"
                            title={t('credits_activity.credits')}
                            icon={<Coins className="w-4 h-4" stroke="currentcolor" />}
                            endAdornment={
                                <Badge className="bg-[#CFF3FF] text-[#004EA2] rounded-[16px] hover:bg-[#CFF3FF] hover:text-[#004EA2] min-w-12 justify-center font-bold">
                                    {isLoading ? (
                                        <Loader2 className="animate-spin w-4 h-4" />
                                    ) : (
                                        details?.balance ?? 0
                                    )}
                                </Badge>
                            }
                        />
                        <SideNavItem
                            path="/apikeys"
                            title={t('api_keys')}
                            icon={<Key className="w-4 h-4" stroke="currentcolor" />}
                        />
                        <SideNavItem
                            path="/aigateway"
                            title={t('ai_gateway.title')}
                            icon={<KeySquare className="w-4 h-4" stroke="currentcolor" />}
                        />
                        <SideNavItem
                            path="/administration/users"
                            title={t('users')}
                            icon={<User className="w-4 h-4" stroke="currentcolor" />}
                        />
                        <SideNavItem
                            path="/administration/groups"
                            title={t('groups')}
                            icon={<Users className="w-4 h-4" stroke="currentcolor" />}
                        />
                        <SideNavItem
                            path="/settings"
                            title={t('settings')}
                            icon={<Settings className="w-4 h-4" stroke="currentcolor" />}
                        />
                    </div>
                </nav>
            </aside>
            {openProjectSelector && (
                <aside
                    ref={selectorRef}
                    tabIndex={0}
                    className="absolute left-60 h-full overflow-y-auto z-10 min-w-60 max-w-60 border-r border-muted bg-card"
                >
                    <nav className="flex flex-col items-stretch gap-9 p-4 text-[13px] tracking-[0.26px]">
                        <div className="flex flex-col gap-2">
                            <div className="flex flex-row justify-between items-center">
                                <p className="text-sidenav-header font-gilroy-bold text-[13px] tracking-[0.26px] uppercase font-normal">
                                    {'ALL PROJECTS'}
                                </p>
                                <CreateNewProject
                                    variant={'ghost'}
                                    size="icon"
                                    Icon={PlusIcon}
                                    onSuccess={() => {
                                        queryClient.invalidateQueries({
                                            queryKey: [QueryKeys.PROJECTS],
                                        })
                                        navigate({ to: '/' })
                                    }}
                                />
                            </div>
                            <Input
                                placeholder={'Search Projects'}
                                onChange={onProjectSearchChange}
                                className={'bg-background'}
                            />
                            {filteredAndSortedProjects.map((project) => (
                                <SideNavItem
                                    key={project.id}
                                    path="/$projectId/pipelines"
                                    projectId={project.id}
                                    title={project.name}
                                    onClick={() => {
                                        setOpenProjectSelector(false)
                                        updateRecentProjects(project.id)
                                    }}
                                    icon={
                                        project.projectIcon ? (
                                            <img
                                                src={project.projectIcon}
                                                alt={project.name}
                                                height={16}
                                                width={16}
                                            />
                                        ) : (
                                            <AiriaIcon className="w-4 h-4" />
                                        )
                                    }
                                />
                            ))}
                        </div>
                    </nav>
                </aside>
            )}
        </>
    )
}
