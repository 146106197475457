import {
    PatchPoolRequestV1,
    PoolResponseV1,
    UpdatePoolV1,
    getTenantDetailsV1,
} from '@/api/marketplace.ts'
import { getAllProjects } from '@/api/projects.ts'
import { Badge } from '@/components/ui/badge.tsx'
import { Button } from '@/components/ui/button.tsx'
import { Dialog, DialogContent } from '@/components/ui/dialog.tsx'
import { Input } from '@/components/ui/input.tsx'
import { Label } from '@/components/ui/label.tsx'
import { toast } from '@/components/ui/use-toast.ts'
import { QueryKeys } from '@/constants/QueryKeys.ts'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { createFileRoute, useRouter } from '@tanstack/react-router'
import { Plus, X } from 'lucide-react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export const Route = createFileRoute('/_mainLayout/credits/$poolId/edit')({
    loader: async ({ params }) => {
        const tenantDetails = await getTenantDetailsV1()
        return tenantDetails?.pools.find((p) => p.id === params.poolId)
    },
    component: () => {
        const navigate = Route.useNavigate()
        return (
            <Dialog modal open onOpenChange={() => navigate({ to: '../' })}>
                <DialogContent className={'max-w-modal-lg max-h-modal'}>
                    <EditCreditPool />
                </DialogContent>
            </Dialog>
        )
    },
})

function EditCreditPool() {
    const { t } = useTranslation()
    const pool: PoolResponseV1 | undefined = Route.useLoaderData()
    const { formState, handleSubmit, register, reset } = useForm<PatchPoolRequestV1>({
        defaultValues: {
            name: pool?.name,
            balanceUpdate: pool?.maxBalance,
            addedProjects: [],
            removedProjects: [],
        },
    })
    const router = useRouter()
    const queryClient = useQueryClient()
    const { poolId } = Route.useParams()
    const mutation = useMutation<PoolResponseV1, null, PatchPoolRequestV1>({
        mutationFn: (data) => UpdatePoolV1(poolId, data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [QueryKeys.TENANT_DETAILS] })
            toast({
                title: t('success_title'),
                description: t('credits.update_success_description'),
            })
        },
        onError: (error) => {
            const description =
                // @ts-ignore
                error?.message == '422'
                    ? t('credits.update_error_assigned')
                    : t('error_description_generic')
            toast({
                title: t('error_title'),
                description,
                variant: 'destructive',
            })
        },
    })
    const { data: projects } = useQuery({
        queryKey: [QueryKeys.PROJECTS],
        queryFn: () => getAllProjects(),
    })
    const { data: details } = useQuery({
        queryKey: [QueryKeys.TENANT_DETAILS],
        queryFn: getTenantDetailsV1,
    })
    const [poolProjects, setPoolProjects] = useState(
        projects?.filter((p) => pool?.projects.includes(p.id)) ?? []
    )
    const [availableProjects, setAvailableProjects] = useState(
        projects?.filter((p) => !poolProjects.map((p) => p.id).includes(p.id)) ?? []
    )

    const onFormSubmit = async (data: PatchPoolRequestV1) => {
        try {
            await mutation.mutateAsync({
                ...data,
                balanceUpdate: data.balanceUpdate && +data.balanceUpdate,
                addedProjects: poolProjects
                    .map((p) => p.id)
                    .filter((p) => !pool?.projects.includes(p)),
                removedProjects:
                    pool?.projects.filter((p) => !poolProjects.map((p) => p.id).includes(p)) ?? [],
            })
            reset()
            router.navigate({ to: '/credits' })
        } catch (err) {
            console.log('error updating credit pool', err)
        }
    }

    const onProjectAddClick = (projectId: string) => {
        const projectToAdd = projects?.find((p) => p.id === projectId)
        if (projectToAdd) {
            setPoolProjects((prev) => [...prev, projectToAdd])
            setAvailableProjects((prev) => prev.filter((p) => p.id !== projectId))
        }
    }

    const onProjectRemoveClick = (projectId: string) => {
        const projectToRemove = projects?.find((p) => p.id === projectId)
        if (projectToRemove) {
            setAvailableProjects((prev) => [...prev, projectToRemove])
            setPoolProjects((prev) => prev.filter((p) => p.id !== projectId))
        }
    }

    return (
        <div className={'flex flex-col gap-4 overflow-auto'}>
            <h4 className={'text-2xl mt-5'}>{t('credits.manage_pool')}</h4>
            <form onSubmit={handleSubmit(onFormSubmit)} className={'h-full'}>
                <div className={'bg-card p-2'}>
                    <Label>New Pool Name</Label>
                    <Input
                        className={'bg-background max-w-[500px]'}
                        {...register('name', { required: true })}
                    />
                </div>
                <div className={'bg-card p-2'}>
                    <Label>Allotted Credits</Label>
                    <div className={'flex gap-4 items-center'}>
                        <Input
                            className={'bg-background max-w-[500px]'}
                            type={'number'}
                            {...register('balanceUpdate', { required: true, max: 100 })}
                        />
                        <span>{`${details?.balance} ${t('credits.available')}`}</span>
                    </div>
                </div>
                <div className={'bg-card p-2 mb-16'}>
                    <Label>Assigned Projects</Label>
                    <div
                        className={
                            'flex bg-background items-center gap-2 overflow-auto h-full flex-wrap'
                        }
                    >
                        {poolProjects.map((p) => (
                            <Badge
                                className={
                                    'rounded-full px-4 py-2 cursor-pointer border-primary bg-primary/20 text-primary'
                                }
                                onClick={() => onProjectRemoveClick(p.id)}
                            >
                                {p.name}&nbsp;
                                <X className={'h-3 w-3'} />
                            </Badge>
                        ))}
                    </div>
                    <div className={'flex gap-2 mt-2 overflow-auto flex-wrap'}>
                        {availableProjects?.map((p) => (
                            <Badge
                                variant={'outline'}
                                className={
                                    'rounded-full px-4 py-2 cursor-pointer border-primary bg-primary/20 text-primary'
                                }
                                onClick={() => onProjectAddClick(p.id)}
                            >
                                {p.name}&nbsp;
                                <Plus className={'h-3 w-3'} />
                            </Badge>
                        ))}
                    </div>
                </div>
                <div className={'items-end pt-16'}>
                    <div className={'flex justify-end gap-4'}>
                        <Button
                            onClick={() => router.history.back()}
                            variant={'outline'}
                            className={'border-primary text-primary'}
                        >
                            {t('cancel')}
                        </Button>
                        <Button type={'submit'} disabled={!formState.isValid}>
                            {t('credits.save_pool')}
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    )
}
