import { ProxyReportData, getProxyReport, getTenantProxyReport } from '@/api/gatewayUsage.ts'
import AnthropicIcon from '@/assets/icons/anthropic.svg?react'
import OllamaIcon from '@/assets/icons/ollama.svg?react'
import OpenAiIcon from '@/assets/icons/openai.svg?react'
import { DefaultDataTable } from '@/components/DefaultDataTable'
import FeedModal from '@/components/home/feed/FeedModal'
import { ProjectSelect } from '@/components/modelLibrary/selectProjectForm.tsx'
import PaginationHeader from '@/components/pagination/paginationHeader'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { QueryKeys } from '@/constants/QueryKeys'
import { cn } from '@/lib/utils'
import { useQuery } from '@tanstack/react-query'
import { ColumnDef } from '@tanstack/react-table'
import React, { useState, useEffect } from 'react'

const Feed: React.FC = () => {
    const [selectedProject, setSelectedProject] = useState<string | undefined>(undefined)
    const [filter, setFilter] = useState<string>('')
    const [filteredFeedData, setFilteredFeedData] = useState<ProxyReportData[]>([])
    const [selectedCall, setSelectedCall] = useState<ProxyReportData | null>(null)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [proxyData, setProxyData] = useState<ProxyReportData[]>([])

    const proxyQuery = useQuery({
        queryKey: [QueryKeys.PROXY_QUERY, selectedProject],
        queryFn: () => getProxyReport(selectedProject ?? ''),
        enabled: !!selectedProject,
    })

    const tenantProxyQuery = useQuery({
        queryKey: [QueryKeys.TENANT_PROXY_QUERY],
        queryFn: getTenantProxyReport,
        enabled: !selectedProject,
    })

    useEffect(() => {
        if (selectedProject != null && selectedProject !== '') {
            setProxyData(proxyQuery.data ?? [])
        } else {
            setProxyData(tenantProxyQuery.data ?? [])
        }
    }, [proxyQuery.data, tenantProxyQuery.data])

    useEffect(() => {
        const sortedData = [...proxyData].sort((a, b) => {
            return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        })
        if (filter !== '') {
            setFilteredFeedData(
                sortedData.filter((item) =>
                    item.requestModel.toLowerCase().includes(filter.toLowerCase())
                )
            )
        } else {
            setFilteredFeedData(sortedData)
        }
    }, [filter, proxyData])

    const renderProviderIcon = (provider: string) => {
        switch (provider) {
            case 'OpenAI':
                return <OpenAiIcon className="w-7 h-7 mr-2" />
            case 'Anthropic':
                return <AnthropicIcon className="w-7 h-7 mr-2" />
            case 'Ollama':
                return <OllamaIcon className="w-7 h-7 mr-2" />
            default:
                return <></>
        }
    }

    const handleRowClick = (row: { original: ProxyReportData }) => {
        setSelectedCall(row.original)
        setIsModalOpen(true)
    }

    const closeModal = () => {
        setIsModalOpen(false)
        setSelectedCall(null)
    }

    const columns: ColumnDef<ProxyReportData, any>[] = [
        {
            accessorKey: 'createdAt',
            header: 'Timestamp',
            cell: ({ row }) => {
                const date = new Date(row.original.createdAt)
                return (
                    <p>{`${date.toLocaleDateString(undefined, { dateStyle: 'short' })} at ${date.toLocaleTimeString(undefined, { timeStyle: 'short' })}`}</p>
                )
            },
        },
        {
            header: 'Model',
            accessorKey: 'requestModel',
            cell: ({ row }) => {
                const model = row.original.requestModel
                return (
                    <div className="flex flex-row items-center">
                        {renderProviderIcon(row.original.providerType)}
                        <div className="flex flex-col">
                            <p className="text-[#3D3D39]">{row.original.providerType}</p>
                            <p className="text-[15px] text-[#5F606B]">{model}</p>
                        </div>
                    </div>
                )
            },
        },
        {
            header: 'API Key',
            accessorKey: 'duration',
            cell: ({ row }) => {
                const parts = row.original.duration.split(':')
                const hours = parseInt(parts[0], 10)
                const minutes = parseInt(parts[1], 10)
                const seconds = parseFloat(parts[2])
                const totalSeconds = hours * 3600 + minutes * 60 + seconds
                const duration = totalSeconds.toFixed(2) + ' seconds'

                return (
                    <div className="flex flex-col">
                        <p className="text-[#3D3D39]">{row.original.apiKeyName}</p>
                        <p className="text-[15px] text-[#5F606B]">{duration}</p>
                    </div>
                )
            },
        },
        {
            header: 'Prompt',
            accessorKey: 'promptTokens',
            cell: ({ row }) => {
                return (
                    <Badge className="rounded-[24px] text-sm px-3 py-[2px] text-[#3D3D39] bg-[#EAF4FF] hover:bg-[#EAF4FF] hover:text-[#3D3D39]">
                        {`${row.original.promptTokens} Credits`}
                    </Badge>
                )
            },
        },
        {
            header: 'Completion',
            accessorKey: 'completionTokens',
            cell: ({ row }) => {
                return (
                    <Badge className="rounded-[24px] text-sm px-3 py-[2px] text-[#3D3D39] bg-[#EAF4FF] hover:bg-[#EAF4FF] hover:text-[#3D3D39]">
                        {`${row.original.completionTokens} Credits`}
                    </Badge>
                )
            },
        },
        {
            header: 'Total',
            accessorKey: 'totalTokens',
            cell: ({ row }) => {
                return (
                    <Badge className="rounded-[24px] text-sm px-3 py-[2px] text-[#3D3D39] bg-[#EAF4FF] hover:bg-[#EAF4FF] hover:text-[#3D3D39]">
                        {`${row.original.totalTokens} Credits`}
                    </Badge>
                )
            },
        },
        {
            header: 'Streamed',
            accessorKey: 'streamed',
            cell: ({ row }) => {
                return (
                    <Badge
                        className={cn(
                            'rounded-[24px] text-sm px-3 py-[2px] w-14 text-[#3D3D39] bg-success hover:bg-success hover:text-[#3D3D39] justify-center',
                            !row.original.streamed && 'bg-error hover:bg-error'
                        )}
                    >
                        {row.original.streamed ? 'True' : 'False'}
                    </Badge>
                )
            },
        },
    ]

    return (
        <div className="flex flex-col gap-2 mt-6">
            <div className="flex flex-row justify-between items-center">
                <div className="flex flex-row gap-2">
                    <div className="min-w-48 w-fit">
                        <ProjectSelect
                            selectedProjectId={selectedProject}
                            setSelectedProjectId={setSelectedProject}
                        />
                    </div>

                    <PaginationHeader
                        filter={filter}
                        setFilter={setFilter}
                        sortOptions={columns}
                        queryParams={{
                            projectId: undefined,
                            pageNumber: 0,
                            pageSize: 0,
                            sortBy: undefined,
                            sortDirection: undefined,
                        }}
                    />
                </div>

                <Button
                    type="button"
                    className="text-primary font-gilroy-bold text-[13px] rounded-sm h-8 bg-[#EAF4FF] shadow-none hover:bg-[#EAF4FFBB] hover:text-primary"
                >
                    Export
                </Button>
            </div>
            <DefaultDataTable
                className="h-[calc(100vh-250px)]"
                columns={columns}
                data={filteredFeedData}
                pagination
                totalCount={filteredFeedData.length}
                rowsPerPage={25}
                onRowClick={handleRowClick}
            />
            {selectedCall && (
                <FeedModal
                    isOpen={isModalOpen}
                    onClose={closeModal}
                    callDetails={selectedCall}
                    projectId={selectedProject}
                />
            )}
        </div>
    )
}

export default Feed
