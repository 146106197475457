import { LoaderCircle } from 'lucide-react'
import millify from 'millify'
import React from 'react'
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

interface UsageChartProps {
    data: any
    dateRange: string
    loading: boolean
    type: 'pipelines' | 'models'
}

const UsageChart: React.FC<UsageChartProps> = ({ data, dateRange, loading }) => {
    const colors = ['#007AFF', '#006bdf', '#208bff', '#60acff', '#9fcdff', '#dfeeff']

    const CustomTooltip = ({ active, payload }: any) => {
        if (active && payload && payload.length) {
            return (
                <div className="bg-background rounded-lg p-2 shadow-lg">
                    {payload.map((item: any) => (
                        <div key={item.name} className="flex flex-row items-center gap-2">
                            <div
                                className="w-3 h-3 rounded-full flex-shrink-0"
                                style={{ backgroundColor: item.color }}
                            />
                            <p className="text-[13px] text-[#3D3D39]">{item.name}:</p>
                            <p className="text-[13px] text-[#3D3D39]">{item.value}</p>
                        </div>
                    ))}
                </div>
            )
        }
        return null
    }

    if (loading) {
        return (
            <div className="w-full h-[400px] justify-center flex flex-col">
                <LoaderCircle className="self-center w-12 h-12 animate-spin" />
            </div>
        )
    }

    return (
        <ResponsiveContainer width="100%" height={180}>
            <BarChart data={data} margin={{ bottom: 0, top: 0, right: 0, left: 0 }}>
                <XAxis
                    dataKey="date"
                    tickLine={false}
                    axisLine={false}
                    tickFormatter={(value) =>
                        dateRange === 'month' ? `${new Date().getMonth() + 1}/${value}` : value
                    }
                    interval={dateRange === 'year' ? 0 : 'equidistantPreserveStart'}
                />
                <YAxis
                    orientation="right"
                    tickLine={false}
                    axisLine={false}
                    tickFormatter={(value) => millify(value)}
                />
                <Tooltip content={<CustomTooltip />} />
                {Object.keys(data[0] || {})
                    .filter((key) => key !== 'date' && key !== 'originalDate')
                    .map((key, index) => (
                        <Bar
                            key={key}
                            dataKey={key}
                            stackId="a"
                            fill={colors[index % colors.length]}
                        />
                    ))}
            </BarChart>
        </ResponsiveContainer>
    )
}

export default UsageChart
