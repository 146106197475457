import { getProjectById } from '@/api/projects'
import AiriaIcon from '@/assets/icons/airia-icon.svg?react'
import Container from '@/assets/icons/container.svg?react'
import Database from '@/assets/icons/database.svg?react'
import DataFlow from '@/assets/icons/dataflow.svg?react'
import SimCard from '@/assets/icons/simcard.svg?react'
import Terminal from '@/assets/icons/terminal.svg?react'
import Tools from '@/assets/icons/tools.svg?react'
import { routeTree } from '@/routeTree.gen.ts'
import * as Tabs from '@radix-ui/react-tabs'
import { Link, Outlet, RoutePaths, createFileRoute } from '@tanstack/react-router'
import { Lock } from 'lucide-react'
import React from 'react'

export const Route = createFileRoute('/_mainLayout/$projectId/_projectLayout')({
    component: () => {
        const params = Route.useParams()
        return <ProjectLayout key={params.projectId} />
    },
    loader: ({ params }) => getProjectById(params.projectId),
})

function ProjectLayout() {
    const ProjectInfo = Route.useLoaderData()
    const TABS: {
        path: RoutePaths<typeof routeTree>
        params: { projectId: string }
        label: string
        icon: JSX.Element
        value: string
    }[] = [
        {
            path: '/$projectId/pipelines',
            params: { projectId: ProjectInfo.id },
            label: 'Pipelines',
            icon: <DataFlow className="w-4 h-4" stroke="currentColor" />,
            value: 'pipelines',
        },
        {
            path: '/$projectId/models',
            params: { projectId: ProjectInfo.id },
            label: 'Models',
            icon: <Container className="w-4 h-4" stroke="currentColor" />,
            value: 'models',
        },
        {
            path: '/$projectId/datasets',
            params: { projectId: ProjectInfo.id },
            label: 'Data Sources',
            icon: <Database className="w-4 h-4" stroke="currentColor" />,
            value: 'datasets',
        },
        {
            path: '/$projectId/prompts',
            params: { projectId: ProjectInfo.id },
            label: 'Prompts',
            icon: <Terminal className="w-4 h-4" stroke="currentColor" />,
            value: 'prompts',
        },
        {
            path: '/$projectId/memory',
            params: { projectId: ProjectInfo.id },
            label: 'Memory',
            icon: <SimCard className="w-4 h-4" stroke="currentColor" />,
            value: 'memory',
        },
        {
            path: '/$projectId/credentials',
            params: { projectId: ProjectInfo.id },
            label: 'Credentials',
            icon: <Lock className="w-4 h-4" stroke="currentColor" />,
            value: 'credentials',
        },
        {
            path: '/$projectId/tools',
            params: { projectId: ProjectInfo.id },
            label: 'Tools',
            icon: <Tools className="w-4 h-4" stroke="currentColor" />,
            value: 'tools',
        },
    ]
    const currentActiveTab = 'pipelines'

    return (
        <div className="flex flex-col pt-6 px-6 h-full">
            <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                    {ProjectInfo.projectIcon ? (
                        <img
                            src={ProjectInfo.projectIcon}
                            alt={ProjectInfo.name}
                            height={16}
                            width={16}
                        />
                    ) : (
                        <AiriaIcon className="w-10 h-10" />
                    )}
                    <h2 className="text-4xl">{ProjectInfo.name}</h2>
                </div>
            </div>

            <Tabs.Root className="flex flex-col gap-5 py-4 " value={currentActiveTab}>
                <Tabs.List
                    className="shrink-0 flex border-b border-mauve6 w-full gap-10 [&_*]:cursor-pointer tabs-step"
                    aria-label="Manage your account"
                >
                    {TABS.map((tab) => (
                        <TabLink key={tab.path} {...tab} />
                    ))}
                </Tabs.List>
            </Tabs.Root>
            <div className="p-1">
                <Outlet />
            </div>
        </div>
    )
}

const TabLink: React.FC<{
    path: RoutePaths<typeof routeTree>
    params: { projectId: string }
    label: string
    value: string
    icon: JSX.Element
}> = ({ path, params, label, value, icon }) => {
    return (
        <Link
            to={path}
            params={params}
            activeProps={{
                className:
                    'text-primary shadow-primary focus:shadow-primary relative shadow-[inset_0_-1px_0_0,0_1px_0_0]',
            }}
        >
            <Tabs.Trigger
                className="bg-background px-1 gap-2 group h-[45px] w-fit flex items-center justify-center text-[15px] leading-none select-none first:rounded-tl-md last:rounded-tr-md outline-none cursor-default"
                value={value}
            >
                <div className={'flex gap-1 pr-1'}>
                    {icon}
                    {label}
                </div>
            </Tabs.Trigger>
        </Link>
    )
}
