import { ProxyReportData, getProxyReportConversation } from '@/api/gatewayUsage.ts'
import { getAllProjects } from '@/api/projects'
import AIChip from '@/assets/icons/ai-chip.svg?react'
import AnthropicIcon from '@/assets/icons/anthropic.svg?react'
import OllamaIcon from '@/assets/icons/ollama.svg?react'
import OpenAiIcon from '@/assets/icons/openai.svg?react'
import Airia from '@/assets/models/Airia.svg?react'
import { Badge } from '@/components/ui/badge'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { QueryKeys } from '@/constants/QueryKeys'
import { cn } from '@/lib/utils'
import { useQuery } from '@tanstack/react-query'
import { themes } from 'prism-react-renderer'
import React, { useEffect, useRef } from 'react'
import { CodeBlock } from 'react-code-block'

interface FeedModalProps {
    isOpen: boolean
    onClose: () => void
    callDetails: ProxyReportData
    projectId?: string
}

const FeedModal: React.FC<FeedModalProps> = ({ isOpen, onClose, callDetails, projectId }) => {
    const chatEndRef = useRef<HTMLDivElement>(null)

    const allProjectsQuery = useQuery({
        queryKey: [QueryKeys.PROJECTS],
        queryFn: getAllProjects,
    })

    const chatHistoryQuery = useQuery({
        queryKey: ['feed-details', callDetails.id],
        queryFn: () => getProxyReportConversation(callDetails.id),
    })

    useEffect(() => {
        setTimeout(() => {
            if (chatEndRef.current) {
                chatEndRef.current.scrollIntoView({ behavior: 'instant' })
            }
        }, 50)
    }, [isOpen])

    const renderProviderIcon = (provider: string) => {
        switch (provider) {
            case 'OpenAI':
                return <OpenAiIcon className="w-7 h-7 mr-2" />
            case 'Anthropic':
                return <AnthropicIcon className="w-7 h-7 mr-2" />
            case 'Ollama':
                return <OllamaIcon className="w-7 h-7 mr-2" />
            default:
                return null
        }
    }

    const renderDuration = (callDuration: string) => {
        const parts = callDuration.split(':')
        const hours = parseInt(parts[0], 10)
        const minutes = parseInt(parts[1], 10)
        const seconds = parseFloat(parts[2])
        const totalSeconds = hours * 3600 + minutes * 60 + seconds
        const duration = totalSeconds.toFixed(2) + ' seconds'

        return <p className="text-[15px] text-[#5F606B]">{duration}</p>
    }

    const renderSelectedProject = (projectId: string | undefined) => {
        const selectedProject = allProjectsQuery.data?.find((project) => project.id === projectId)
        return selectedProject ? (
            <div className={'flex gap-6 items-center'}>
                {selectedProject.projectIcon ? (
                    <img src={selectedProject.projectIcon} className={'h-12 w-12 mr-1'} />
                ) : (
                    <Airia className={'h-12 w-12'} />
                )}
                <p className="font-gilroy-medium text-4xl text-[#3D3D39]">{selectedProject.name}</p>
            </div>
        ) : (
            <div className={'flex gap-6 items-center'}>
                <AIChip className={'h-12 w-12'} />
                <p className="font-gilroy-medium text-4xl text-[#3D3D39]">AI Gateway</p>
            </div>
        )
    }

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="max-w-modal-lg max-h-[1000px]">
                <DialogHeader>
                    <DialogTitle>Call details</DialogTitle>
                </DialogHeader>
                <div className="flex flex-col space-y-4">
                    <div className="flex justify-between">
                        {renderSelectedProject(projectId)}
                        <div className="text-sm text-gray-500 text-right">
                            <div>Client IP: {callDetails.clientIpAddress}</div>
                            <div>User Agent: {callDetails.userAgent}</div>
                            <div>{new Date(callDetails.createdAt).toLocaleString()}</div>
                        </div>
                    </div>

                    <table className="w-full text-sm">
                        <thead>
                            <tr className="text-left text-gray-500">
                                <th>Model</th>
                                <th>API Key</th>
                                <th>Prompt</th>
                                <th>Completion</th>
                                <th>Total</th>
                                <th>Streamed</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="py-2">
                                    <div className="flex items-center space-x-2">
                                        {renderProviderIcon(callDetails.providerType)}
                                        <div className="flex flex-col">
                                            <p className="text-[#3D3D39]">
                                                {callDetails.providerType}
                                            </p>
                                            <p className="text-[15px] text-[#5F606B]">
                                                {callDetails.requestModel}
                                            </p>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="flex flex-col">
                                        <p className="text-[#3D3D39]">{callDetails.apiKeyName}</p>
                                        {renderDuration(callDetails.duration)}
                                    </div>
                                </td>
                                <td>
                                    <Badge className="rounded-[24px] text-sm px-3 py-[2px] text-[#3D3D39] bg-[#EAF4FF] hover:bg-[#EAF4FF] hover:text-[#3D3D39]">
                                        {`${callDetails.promptTokens} Credits`}
                                    </Badge>
                                </td>
                                <td>
                                    <Badge className="rounded-[24px] text-sm px-3 py-[2px] text-[#3D3D39] bg-[#EAF4FF] hover:bg-[#EAF4FF] hover:text-[#3D3D39]">
                                        {`${callDetails.completionTokens} Credits`}
                                    </Badge>
                                </td>
                                <td>
                                    <Badge className="rounded-[24px] text-sm px-3 py-[2px] text-[#3D3D39] bg-[#EAF4FF] hover:bg-[#EAF4FF] hover:text-[#3D3D39]">
                                        {`${callDetails.totalTokens} Credits`}
                                    </Badge>
                                </td>
                                <td>
                                    <Badge
                                        className={cn(
                                            'rounded-[24px] text-sm px-3 py-[2px] w-14 text-[#3D3D39] bg-success hover:bg-success hover:text-[#3D3D39] justify-center',
                                            !callDetails.streamed && 'bg-error hover:bg-error'
                                        )}
                                    >
                                        {callDetails.streamed ? 'True' : 'False'}
                                    </Badge>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div className="mt-4">
                        <h3 className="font-semibold mb-2">Chat History</h3>
                        <div className="space-y-4 h-[400px] overflow-y-auto px-4">
                            {chatHistoryQuery.data &&
                                chatHistoryQuery.data.map((message, index) => (
                                    <div
                                        key={index}
                                        className={cn(
                                            'p-4 rounded-lg',
                                            message.role == 'system' || message.role == 'assistant'
                                                ? 'bg-blue-50'
                                                : 'bg-green-50'
                                        )}
                                    >
                                        <div className="font-semibold">
                                            {message.role == 'system' || message.role == 'assistant'
                                                ? 'Response'
                                                : 'Request'}
                                        </div>
                                        <CodeBlock
                                            language="js"
                                            code={message.message}
                                            theme={themes.vsLight}
                                        >
                                            <CodeBlock.Code
                                                as="div"
                                                className="mt-1 p-2 rounded-xl bg-inherit text-wrap whitespace-pre-wrap break-all overflow-y-scroll"
                                            >
                                                <div className="table-row overflow-auto">
                                                    <CodeBlock.LineContent className="table-cell text-sm">
                                                        <CodeBlock.Token />
                                                    </CodeBlock.LineContent>
                                                </div>
                                            </CodeBlock.Code>
                                        </CodeBlock>
                                        <div className="text-xs text-gray-500 mt-2">
                                            {message.createdAt}
                                        </div>
                                    </div>
                                ))}
                            <div ref={chatEndRef} />
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default FeedModal
